<template>
  <div>
    <b-sidebar
      id="add-bus-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addServiceSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ assetLocal.id > 0 ? 'Edit Asset' : 'Add Asset' }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Nama Asset"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Asset"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="assetLocal.nama_asset"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nama Asset"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Jumlah"
                  label-for="jumlah"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah"
                    rules="required"
                  >
                    <b-form-input
                      id="jumlah"
                      v-model="assetLocal.jumlah"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Jumlah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!assetLocal.id"
                md="12"
              >
                <b-form-group
                  label="Status Pembayaran"
                  label-for="status-pembayaran-asset"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Status Pembayaran"
                    rules="required"
                  >
                    <b-form-select
                      id="status-pembayaran-asset"
                      v-model="assetLocal.status_pembayaran"
                      :options="options_pembayaran"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Status Pembayaran"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!assetLocal.id && assetLocal.status_pembayaran === 1"
                md="12"
              >
                <b-form-group
                  label="Harga"
                  label-for="harga"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Harga"
                    rules="required"
                  >
                    <b-form-input
                      id="harga"
                      v-model="assetLocal.harga"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Harga"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="!assetLocal.id"
                md="12"
              >
                <b-form-group
                  label="Foto Nota"
                  label-for="foto-nota"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Foto Nota"
                  >
                    <b-form-file
                      id="foto-nota"
                      v-model="assetLocal.foto_nota"
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Pilih File"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormSelect, BFormFile,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormSelect,
    BFormFile,
  },
  model: {
    prop: 'addServiceSidebar',
  },
  props: {
    addServiceSidebar: {
      type: Boolean,
      required: true,
    },
    asset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      assetLocal: {
        id: null,
        nama_asset: null,
        jumlah: null,
        status_pembayaran: null,
        harga: null,
        foto_nota: null,
      },
      options_pembayaran: [
        { value: 1, text: 'Beli Baru' },
        { value: 0, text: 'Sudah Ada' },
      ],
    }
  },
  watch: {
    asset() {
      this.resetTaskLocal()
    },
  },
  methods: {
    resetTaskLocal() {
      this.assetLocal = {
        id: this.asset.id,
        nama_asset: this.asset.nama_asset,
        jumlah: this.asset.jumlah,
        status_pembayaran: this.asset.status_pembayaran,
        harga: this.asset.harga,
        foto_nota: this.asset.foto_nota,
      }
    },
    changeValue() {
      const data = {
        id: null,
        nama_asset: null,
        jumlah: null,
        status_pembayaran: null,
        harga: null,
        foto_nota: null,
      }
      this.$emit('asset-sidebar-change', data, false)
    },
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('submit-data-asset', this.assetLocal)
        }
      })
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
