var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-suku-cadang-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.updateAssetSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.typeAmbil)+" Asset ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jumlah","label-for":"jumlah"}},[_c('validation-provider',{attrs:{"name":"Jumlah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"jumlah","state":errors.length > 0 ? false:null,"placeholder":"Jumlah"},model:{value:(_vm.assetLocal.jumlah),callback:function ($$v) {_vm.$set(_vm.assetLocal, "jumlah", $$v)},expression:"assetLocal.jumlah"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Harga","label-for":"harga"}},[_c('validation-provider',{attrs:{"name":"Harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"harga","state":errors.length > 0 ? false:null,"placeholder":"Harga"},model:{value:(_vm.assetLocal.harga),callback:function ($$v) {_vm.$set(_vm.assetLocal, "harga", $$v)},expression:"assetLocal.harga"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Bukti","label-for":"foto-bukti"}},[_c('validation-provider',{attrs:{"name":"Bukti","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"foto-bukti","accept":"image/*","state":errors.length > 0 ? false:null,"placeholder":"Pilih File"},model:{value:(_vm.assetLocal.foto_bukti),callback:function ($$v) {_vm.$set(_vm.assetLocal, "foto_bukti", $$v)},expression:"assetLocal.foto_bukti"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }